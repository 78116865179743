<template>
  <v-container id="blogHomePage" tag="section">
    <div class="title">
      <label>
        RESOURCES FOR BUYERS & SELLERS
      </label>
    </div>
    <v-row class="blogs">
      <v-col
        class="py-4 px-2"
        cols="12"
        md="4"
        v-for="blog in blogs"
        :key="blog.id"
      >
        <v-card
          :href="`/blogs/${blog.route}`"
          tag="a"
          target="_blank"
          class="mx-auto"
          max-width="380"
        >
          <v-img
            :src="require(`@/assets/blogs/${blog.image}`)"
            height="200px"
          ></v-img>

          <v-card-title>{{ blog.title }}</v-card-title>
          <v-card-text>{{ blog.description }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BlogsHomePage",
  data() {
    return {
      blogs: [
        {
          route: "avoiding-pitfalls",
          title: "Avoiding Pitfalls",
          image: "avoiding-pitfalls.JPG",
          description: "10 common mistakes home sellers should steer clear of.",
        },
        {
          route: "preparing-for-sale",
          title: "Preparing for Sale",
          image: "preparing-sale.JPG",
          description: "Essential tips for sellers.",
        },
        {
          route: "first-home",
          title: "Buying Your First Home",
          image: "first-home.JPG",
          description: "Five tips for millennials buying their first home.",
        },
        {
          route: "buyer-mistakes",
          title: "Buyer Mistakes",
          image: "buyer-mistakes.JPG",
          description: "Common mistakes first-time homebuyers make.",
        },
        {
          route: "closing-costs",
          title: "Closing Costs",
          image: "closing-costs.JPG",
          description:
            "Learn about how much it will cost you to close on a deal.",
        },
      ],
    };
  },
};
</script>

<style scoped>
a.v-card {
  color: inherit;
  text-decoration: none;
}

.blogs {
  padding-top: 40px;
}

.title {
  padding-top: 15px;
  text-align: center;
  font-weight: bold;
  font-size: 2em !important;
}
</style>
